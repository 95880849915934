<template>
  <div class="resourceManage fx-col fx-1">
    <el-card>
      <div>
        <el-form
          class="form-item-no-mb"
          ref="searchForm"
          inline
          :model="searchForm"
          size="small"
        >
          <el-form-item label="文件名">
            <el-input
              v-model="searchForm.fileName"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="文件类型">
            <el-select
              v-model="searchForm.fileType"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in fileTypeList"
                :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="reset">重置</el-button>
            <el-button type="primary" @click="query()">查询</el-button>
          </el-form-item>

        </el-form>
      </div>
    </el-card>
    <div class="fx-row mt16 bac-fff fx-1">
      <!-- 资源分组 -->
      <ResourceGrouping
        title="知识库分组"
        pageType="student"
        :treeData="treeData"
        :defaultProps="defaultProps"
        @clickGroupNode="_clickGroupNode"
      />
      <!-- 列表 -->
      <div class="w200 fx-1">
        <el-table
          class="index-list-table"
          :data="tableData"
          height="calc(100vh - 350px)"
        >
          <el-table-column prop="fileName" label="文件名称">
            <template slot-scope="{ row }">
              {{ row.fileName || "—" }}
            </template>
          </el-table-column>
          <el-table-column prop="groupName" label="所属分组">
            <template slot-scope="{ row }">
              {{ row.groupName || "—" }}
            </template>
          </el-table-column>
          <el-table-column prop="fileType" label="文件类型">
            <template slot-scope="{ row }">
              {{ row.fileTypeValue || "—" }}
            </template>
          </el-table-column>
          <el-table-column prop="size" label="文件大小">
            <template slot-scope="{ row }">
              {{ row.size ? bytesToSize(row.size) : "—" }}
            </template>
          </el-table-column>
          <el-table-column prop="fileDuration" label="文件时长(min)">
            <template slot-scope="{ row }">
              {{ row.fileDuration ? formatSeconds(row.fileDuration) : "—" }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240" fixed="right">
            <template slot-scope="{row}">
              <el-button
                type="text"
                class="text-btn mr24"
                @click.native="_previewFileHandle(row)"
              >
                预览</el-button
              >
              <el-button
                type="text"
                class="text-btn mr24"
                :disabled="row.downloadFlag == 0"
                @click.native="downloadFileDetail(row)"
                >下载</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="fx-row-end pt16"
          @size-change="_handleSizeChange"
          @current-change="_handleCurrentChange"
          :current-page="searchForm.page"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchForm.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 列表预览 -->
    <PreviewFile
      v-if="previewFileShow"
      :previewFileShow="previewFileShow"
      :currentFile="currentFile"
      ref="previewFile"
    ></PreviewFile>

  </div>
</template>
<script>
import ResourceGrouping from '@/components/resourceGrouping/index'
import { getKnowledgeGroupApi, getKnowledgeListApi, getKonwledgeDetailApi } from '@/api/knowledge'
import fileType from '@/mixins/fileType'
import PreviewFile from '@/components/previewFile/index.vue'
export default {
  mixins: [fileType],
  components: {
    ResourceGrouping,
    PreviewFile
  },
  data () {
    return {
      tableData: [],
      searchForm: {
        fileName: '',
        fileType: '',
        groupId: '',
        page: 1,
        pageSize: 10,
        total: 1
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      options: [],
      currentFileUrl: '',
      fileTypeList: [
        {
          name: '图片',
          value: 'image'
        },
        {
          name: '视频',
          value: 'video'
        },
        {
          name: '文档',
          value: 'file'
        }
      ], // 文件类型
      currentFile: {}, // 当前行数据
      previewFileShow: false
    }
  },
  created () {
    this.getTree()
    this.queryList()
  },
  methods: {
    reset () {
      this.searchForm = {
        fileName: '',
        fileType: '',
        groupId: this.searchForm.groupId,
        page: 1,
        pageSize: 10,
        total: 1
      }
      this.queryList()
    },
    _clickGroupNode (data) {
      // 点击分组查询
      this.searchForm.groupId = data.value
      this.searchForm.page = 1
      this.queryList(data)
    },
    query () {
      this.searchForm.page = 1
      this.queryList()
    },
    queryList (item) {
      getKnowledgeListApi({
        groupId: item && item.value,
        ...this.searchForm
      }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.object.results
          this.searchForm.total = res.object.total
        }
      })
    },
    getTree () {
      getKnowledgeGroupApi().then((res) => {
        if (res.code === 200) {
          this.treeData = res.data
        }
      })
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this.queryList()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this.queryList()
    },

    _previewFileHandle (row) {
      // 列表预览
      if (row.fileType === 'image' || row.fileType === 'video') {
        this.previewFileShow = true
        this.currentFile = row
      } else {
        const previewUrl = this.getPreviewUrl(row)
        window.open(previewUrl)
      }
    },
    downloadFileDetail (item) {
      // 点击下载调取详情接口
      getKonwledgeDetailApi({
        id: item.id
      }).then(res => {
        if (res.code === 200) {
          if (res.data.downloadFlag) {
            this.downloadFile(res.data)
          } else {
            this.$message.info('请联系相关负责人！')
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 0;
  }
}
/deep/.index-list-table {
  thead {
    background-color: #ffffff !important;
  }
  tr {
    height: 51px;
    height: 54px;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
  th {
    background: #f6f7f9 !important;
  }
}
.add-group-btn {
  width: 116px;
  border: 1px solid #0089ff;
  font-size: 14px;
  color: #0089ff;
}
.delete-group-btn {
  width: 60px;
  border: 1px solid #f04134;
  font-size: 14px;
  color: #f04134;
}
/deep/.el-form-item {
  margin-right: 32px !important;
}
/deep/.el-form-item__label {
  color: #000000;
}
/deep/ .el-button {
  font-size: 14px;
  height: 32px;
}
/deep/.el-button--text {
  margin: 0 10px;
}
.left-box {
  margin-right: 8px;
  width: 260px;
  border-right: 1px solid #f3f3f3;
  .left-box-title {
    padding: 16px 16px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    border-bottom: 1px solid #f3f3f3;
  }
}
/deep/.el-tree-node__label {
  font-weight: 500;
  color: #303133;
}
// /deep/.el-icon-caret-right {
//   color: #303133;
// }
</style>
