import { post, get } from '@/utils/request'
const path = '/train'
const API = {
  getKnowledgeGroup: path + '/knowledgeGroup/myFindTree', // 获取知识库分组
  getKnowledgeList: path + '/knowledgeFile/myFindByPage', // 获取我的知识库列表
  getKonwledgeDetail: path + '/knowledgeFile/detail'
}
export const getKnowledgeGroupApi = (data) => get(API.getKnowledgeGroup, data)
export const getKnowledgeListApi = (data) => post(API.getKnowledgeList, data)
export const getKonwledgeDetailApi = (data) => get(API.getKonwledgeDetail, data)
